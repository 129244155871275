import request from '@/utils/service'

/**
 * 获取 Tilt Sensor
 * @author peng-xiao-shuai
 */
export function tiltFind() {
  return request({
    url: '/tower/data/tilt/findList',
    method: 'get'
  })
}

/**
 * Tilt Sensor 列表
 * @author peng-xiao-shuai
 * @param {{ pageNo: number, pageSize: number, condition: string, beginTime: string, endTime: string}} data
 */
export function dataFindPage(data) {
  return request({
    url: '/tower/data/findPage',
    data
  })
}

/**
 * Tilt Sensor 导出 CSV
 * @author peng-xiao-shuai
 */
export function findPageOutCSV(data) {
  return request({
    url: '/tower/data/findPageOutCSV',
    data
  })
}

/**
 * Ground Camera 相机列表
 * @author peng-xiao-shuai
 * @param {string} type 1-普通相机 2-地面相机
 */
export function cameraFindList(params) {
  return request({
    url: '/tower/photo/camera/findList',
    params
  })
}

/**
 * Tower Camera 查询列表
 * @author peng-xiao-shuai
 * @param {{ camera: string, type: ground | normal, towerCode: string, endTime: string, beginTime: string }} data
 */
export function photoFindList(data) {
  return request({
    url: '/tower/photo/findList',
    data
  })
}

/**
 * 查询地面相机列表
 * @author peng-xiao-shuai
 */
export function groundCameraFindList(data) {
  return request({
    url: '/tower/photo/groundCamera/findList',
    data
  })
}

/**
 * 更新照片信息
 * @author peng-xiao-shuai
 * @param {{ defectType: string, desc: string, id: string, severity: string }} data
 */
export function updatePhoto(data) {
  return request({
    url: '/tower/photo/updatePhoto',
    data
  })
}

/**
 * Documents 查询列表
 * @author peng-xiao-shuai
 * @param {{ towerCode: string, type: string }} data
 */
export function fileFindList(data) {
  return request({
    url: '/tower/file/findList',
    data
  })
}

/**
 * Documents 上传一个电塔文件
 * @author peng-xiao-shuai
 * @param {{ towerCode: string, type: string, file: File }} data
 */
export function fileUploadOneFile(data) {
  return request({
    url: '/tower/file/uploadOneFile',
    data
  })
}

/**
 * Documents 上传一个电塔文件
 * @author peng-xiao-shuai
 * @param {string} id
 */
export function deleteOneFile(id) {
  return request({
    url: '/tower/file/deleteOne',
    params: {
      id
    }
  })
}
