<!-- 单独为这个项目封装组件样式 -->
<template>
  <div :class="['date-picker', { focus: isSelect && !value }, type]">
    <el-date-picker
      v-if="type === 'date' || type === 'datetime' || type === 'daterange'"
      v-model="values"
      :picker-options="pickerOptions"
      :type="type"
      range-separator="-"
      align="left"
      ref="date-picker"
      :clearable="isClear"
      @focus="isSelect = true"
      @blur="isSelect = false"
      @change="handleChange"
    >
    </el-date-picker>

    <!-- 滚动时会出现 字符串溢出报错 不用管 element 也会有这个问题 -->
    <el-time-picker
      v-if="type === 'time'"
      is-range
      v-model="values"
      :picker-options="pickerOptions"
      range-separator="-"
      align="left"
      ref="time-picker"
      :clearable="isClear"
      @focus="isSelect = true"
      @blur="isSelect = false"
      @change="handleChange"
    >
    </el-time-picker>

    <div class="value _font-weight__600 _pointer">
      <span v-if="values">
        {{ valueRender() }}
      </span>
      <span v-else>{{ placeholder || textRender() }}</span>
    </div>
  </div>
</template>

<script>
import { parseTime } from '@/utils'

export default {
  props: {
    placeholder: String,
    value: [Array, String, Number, Date],
    type: {
      type: String,
      default: 'date'
    },
    isClear: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      values: '',
      isSelect: false,

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        this.values = val
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleChange(e) {
      const val = Array.isArray(e) ? [parseTime(e[0]), parseTime(e[1])] : parseTime(e)
      this.$emit('input', val)
      this.$emit('change', val)
    },
    valueRender() {
      const format = this.type === 'time' ? '{h}:{i}:{s}' : 'reg'
      if (this.type === 'date') {
        return `${parseTime(this.values, format)}`
      }

      if (this.type === 'datetime') {
        return `${parseTime(this.values, 'reg')} ${parseTime(this.values, '{h}:{i}:{s}')}`
      }
      return `${parseTime(this.values[0], format)} - ${parseTime(this.values[1], format)}`
    },
    textRender() {
      if (this.type === 'datetime') {
        return 'All Date Time'
      }
      return `Start Date - Date Closed`
    }
  }
}
</script>

<style lang="scss" scoped>
.date-picker {
  width: 210px;
  height: 100%;
  position: relative;

  ::v-deep .el-date-editor {
    width: 100% !important;
    height: 100% !important;
    color: var(--color-black);
    border: none;
    border-bottom: 1px solid var(--color-info);
    border-radius: 0px;
    background: rgba(0, 0, 0, 0);
    font-size: 12px;
    padding: 0 0 !important;

    .el-input__prefix {
      display: none;
    }

    &:hover {
      border-bottom-color: var(--color-info);
    }

    > * {
      background: rgba(0, 0, 0, 0);
    }

    input {
      width: 42% !important;
      border: none;
      height: 100%;
      font-size: 12px;
      cursor: pointer;
      &::placeholder {
        font-weight: 600;
        color: var(--color-black) !important;
      }
    }

    & > .el-range-separator {
      width: 8% !important;
    }

    > i.el-icon-date,
    > i.el-icon-time {
      display: none !important;
    }

    .el-input__suffix {
      right: -4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > i.el-range__close-icon,
    .el-input__icon {
      cursor: pointer;

      &.el-icon-circle-close::before {
        content: '\e79d';
        color: var(--color-info-light-5);
      }

      &::before {
        font-family: 'element-icons' !important;
        display: inline-block;
        content: '\e78f';
        transition: transform 0.3s;
        transform: rotateZ(180deg);
        color: var(--color-black);
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: baseline;
      }
      color: var(--color-info-light-5) !important;
      font-size: 14px;
    }
  }

  &.focus {
    ::v-deep .el-range__close-icon,
    ::v-deep .el-input__icon {
      &::before {
        transform: rotateZ(0deg) !important;
      }
    }
  }

  &.datetime,
  &.date {
    ::v-deep input {
      width: 100% !important;
    }
  }

  .value {
    position: absolute;
    top: -1px;
    left: 0;
    width: calc(100% - 15px);
    background: rgb(249, 249, 249);
    pointer-events: none;
    font-size: 12px;
  }
}
</style>
