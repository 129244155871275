<template>
  <div class="tower-camera">
    <Transition name="slate" mode="out-in">
      <div v-show="!detailShow">
        <div
          class="data-page__title _flex _flex-justify__between _flex-align__center _font-weight__600 _font-size__heading3"
        >
          <span>
            <!-- Inspection Date: {{ dateArr[2] }} {{ dateArr[1] }} {{ dateArr[3] }} -->
          </span>

          <el-button type="info" :disabled="!cameraImgList.length" @click="handleDown">
            Download Images
          </el-button>
        </div>

        <el-form
          class="data-page__form-box"
          ref="form"
          :model="formData"
          inline
          label-position="left"
        >
          <el-form-item label="Camera">
            <el-select v-model="formData.camera" placeholder="All camera" @change="getData">
              <el-option v-for="item in cameraList" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Date and Time">
            <SelectDate type="datetime" v-model="formData.beginTime" @change="getData"></SelectDate>
          </el-form-item>
        </el-form>

        <!-- width = 270(图片宽度) * 4 + 75(.list-item__deg 宽度) + 20px(美观) -->
        <el-scrollbar
          class="tower-camera__list data-page__scrollbar"
          :style="{ height: scrollHeight + 'px', width: 'calc(270px * 4 + 75px + 20px)' }"
        >
          <template v-if="cameraImgList && cameraImgList.length">
            <template v-if="showType === 1">
              <div
                v-for="(item, index) in cameraImgList"
                :key="index"
                class="list-item _flex _flex-align__center"
              >
                <div
                  :data-deg="item[0].data[0].photoInclineAngle + '°'"
                  class="list-item__deg"
                ></div>
                <div
                  class="list-item__img"
                  v-for="imgs in item"
                  :key="imgs.direction"
                  :data-direction="imgs.direction"
                  @click="handleShowDetail(imgs.data && imgs.data[0])"
                >
                  <el-image :src="imgs.data.length ? imgs.data[0].filePath : ''" fit="cover" />
                </div>
              </div>
            </template>

            <template v-if="showType === 3">
              <!-- :preview-src-list="cameraImgList.map((item) => item.filePath)" -->
              <el-image
                :src="cameraImgList[0].filePath"
                @click="handleShowDetail(cameraImgList && cameraImgList[0])"
                fit="cover"
              />
            </template>
          </template>

          <LoadingData v-else :loading="loading"></LoadingData>
        </el-scrollbar>
      </div>
    </Transition>

    <Transition name="slate" mode="out-in">
      <TowerCameraDetail
        v-show="detailShow"
        :oldS3Key="imgDetail.oldS3Key"
        :imgDetail="imgDetail"
        @change="detailShow = false"
      />
    </Transition>
  </div>
</template>

<script>
import { cameraFindList, photoFindList } from '@/api/data'
import { batchDownload } from '@/utils/batch-save'
import SelectDate from '@/components/select-datetime/select-date'
import TowerCameraDetail from './tower-camera-detail'

export default {
  components: {
    TowerCameraDetail,
    SelectDate
  },
  props: {
    dateArr: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: true,
      // 表单区域数据
      formData: {
        camera: '',
        type: 'normal',
        beginTime: '',
        towerCode: this.$store.state.user.currentTowerCode
      },
      scrollHeight: 0,

      cameraList: [],
      cameraImgList: [],

      detailShow: false,
      imgDetail: {}
    }
  },
  mounted() {
    const dom = document.getElementsByClassName('tower-camera__list')
    this.scrollHeight = dom.length
      ? window.innerHeight - dom[0].getBoundingClientRect().top - 30
      : 600

    cameraFindList({
      type: 1,
      towerCode: this.$store.state.user.currentTowerCode
    }).then((res) => {
      this.cameraList = res.result
      this.formData.camera = res.result ? res.result[0] : ''

      this.getData()
    })
  },
  watch: {
    '$route.query': {
      handler(val) {
        if (val.time) {
          this.formData.beginTime = `${val.time} 00:00:00`
        } else this.formData.beginTime = ''
      },
      immediate: true
    }
  },
  methods: {
    getData() {
      this.loading = true
      this.cameraImgList = []
      photoFindList(this.formData)
        .then((res) => {
          this.loading = false
          this.cameraImgList = res.result.data
          this.showType = res.result.restType
          console.log('res.result', res.result)
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleShowDetail(item) {
      if (item.oldS3Key) {
        this.imgDetail = item
        this.detailShow = true
      }
    },
    handleDown() {
      const img = []
      this.cameraImgList.forEach((item) => {
        if (Array.isArray(item)) {
          item.forEach((imgs) => {
            console.log()
            if (imgs.data.length) {
              img.push(
                process.env.NODE_ENV === 'production'
                  ? imgs.data[0].filePath
                  : `file/${imgs.data[0].fileRelativePath}`
              )
            }
          })
        } else {
          img.push(item.filePath)
        }
      })
      batchDownload(img, 'Tower Camera')
    }
  }
}
</script>

<style lang="scss" scoped>
.tower-camera {
  // position: relative;
  width: 100%;

  &__list {
    border-radius: 10px;
    padding: 10px 0 10px 10px;
    background: var(--color-white);

    .list-item {
      height: 210px;
      position: relative;
      border-bottom: 1.5px solid var(--color-black);

      &:first-of-type .list-item__img {
        height: 100%;
        background: var(--color-white);
        position: relative;
        &::before {
          content: '';
          width: 100%;
          height: 50px;
          z-index: 1;
          position: absolute;
          left: 0;
          background: var(--color-white);
        }
        &::after {
          content: attr(data-direction);
          position: absolute;
          left: 0;
          top: 25px;
          z-index: 2;
          padding: 3px 5px;
          font-size: 14px;
          background: var(--color-black);
          color: var(--color-white);
          transform: translateY(-50%);
        }
      }

      &__deg {
        width: 75px;
        height: 100%;
        position: relative;
        &::after {
          content: attr(data-deg);
          width: 40px;
          position: absolute;
          left: 0;
          bottom: 0;
          color: var(--color-white);
          background: var(--color-black);
          text-align: center;
          padding: 3px 0;
        }
      }

      &__img {
        width: 270px;
        height: 100%;
        padding-right: 10px;
        // margin-right: 10px;
        border-left: 1.5px solid var(--color-black);

        .el-image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .empty-image-box {
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    span {
      font-weight: bold;
      display: block;
      width: 100%;
      color: #ccc;
      text-align: center;
    }
  }
}
</style>
